import type { FC } from "react";
import { hint } from "../objects/chatMessage";
import { RiLightbulbLine } from "react-icons/ri";
import { BsCardChecklist, BsQuestionCircle } from "react-icons/bs";
import { BiBuildings, BiCoinStack, BiCommand } from "react-icons/bi";
import { TbInvoice } from "react-icons/tb";
import { IoAttachOutline, IoBarChartOutline } from "react-icons/io5";
import { randomStr } from "../utils/helper";
import { MdAnalytics } from "react-icons/md";
import { FaSalesforce } from "react-icons/fa6";
import { GrScorecard } from "react-icons/gr";
import { PiDetectiveBold } from "react-icons/pi";
import { FaBalanceScale } from "react-icons/fa";

interface HintBoxProps {
  onClick: (h: hint) => void;
}

const HintBox: FC<HintBoxProps> = ({ onClick }) => {
  const hints: hint[] = [
    {
      text: "Berikan Inspirasi Bisnis Hari Ini",
      icon: <RiLightbulbLine size={16} className="text-yellow-500" />,
    },
    {
      text: "Apa yang dimaksud dengan Cash Flow",
      icon: <BsQuestionCircle size={16} className="text-green-400" />,
    },
    {
      text: "Buatkan Transaksi Pembelian Listrik sebesai 100000 dari Kas Kecil",
      icon: <BiCommand size={16} className="text-red-600" />,
    },
    {
      text: "Tampilkan 10 Transaksi bulan lalu",
      icon: <BsCardChecklist size={16} className="text-indigo-500" />,
    },
    {
      text: "Buatkan faktur penjualan untuk pak Bambang dengan item berikut ini : 1 pasang Sepatu Olahraga Harga 500000 dan 5 Raket Badminton Harga 1500000 berikan diskon 20%",
      icon: <TbInvoice size={16} className="text-pink-500" />,
    },
    {
      text: "Buatkan faktur pembelian berdasarkan foto ini",
      icon: <IoAttachOutline size={16} className="text-orange-500" />,
      url: "https://api.jaraya.id/assets/images/receipt-with-calculated-tip-sample-min.jpg",
    },
    {
      text: "Analisa peluang bisnis pencucian sepatu",
      icon: <MdAnalytics size={16} className="text-cyan-500" />,
    },
    {
      text: "Buatkan forecasting penjualan sampai semester pertama tahun depan",
      icon: <FaSalesforce size={16} className="text-blue-500" />,
    },
    {
      text: "Hitung Cashflow Health Score untuk periode 6 bulan",
      icon: <GrScorecard size={16} className="text-teal-500" />,
    },
    {
      text: "Identifikasi transaksi atau pola pengeluaran yang tidak biasa dari transaksi 1 tahun terakhir. Berikan penjelasan apakah ada potensi pengeluaran yang dapat dikurangi atau perlu perhatian lebih",
      icon: <PiDetectiveBold size={16} className="text-purple-500" />,
    },
    {
      text: "Hitung rasio kesehatan keuangan perusahaan berdasarkan data neraca berikut. Berikan interpretasi setiap rasio, identifikasi risiko yang mungkin muncul, dan berikan rekomendasi untuk meningkatkan rasio jika diperlukan",
      icon: <FaBalanceScale size={16} className="text-gray-500" />,
    },
    {
      text: "Analisis efisiensi penggunaan aset perusahaan berdasarkan data berikut. Identifikasi apakah aset digunakan secara optimal atau ada aset yang kurang produktif",
      icon: <BiBuildings size={16} className="text-yellow-700" />,
    },
    {
      text: "Analisis risiko utang perusahaan berdasarkan data berikut. Identifikasi kewajiban yang mendekati jatuh tempo dan evaluasi kemampuan perusahaan untuk membayar utang tersebut dengan Jadwal pembayaran utang: Rp10.000.000/bulan :",
      icon: <BiCoinStack size={16} className="text-violet-700" />,
    },
    {
      text: "Analisis pertumbuhan keuangan perusahaan berdasarkan data berikut. Proyeksikan pertumbuhan aset, kewajiban, dan ekuitas dalam 3 tahun ke depan. Berikan rekomendasi untuk mencapai pertumbuhan yang sehat.",
      icon: <IoBarChartOutline size={16} className="text-violet-700" />,
    },
  ];

  return (
    <div className="grid grid-cols-3 gap-4 w-full">
      {hints.map((e) => (
        <div
          className=" aspect-square border rounded-2xl bg-white shadow-lg shadow-gray-200  flex flex-col justify-between p-4 cursor-pointer"
          key={randomStr(6)}
          onClick={() => onClick(e)}
        >
          {e.icon}
          <div className="text-xs h-[48px] line-clamp-3">{e.text}</div>
        </div>
      ))}
    </div>
  );
};
export default HintBox;
