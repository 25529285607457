import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom' as const,
    },
    // title: {
    //   display: true,
    //   text: 'Chart.js Bar Chart',
    // },
  },
};




export function CustomChart({ labels, datasets, height }: {
  height?: string | number| undefined,
  labels: string[];
  datasets: {
    label: string;
    data: any[];
    backgroundColor: string;
  }[]
}) {
  return <Bar height={height} options={options} data={{
    labels,
    datasets
  }} />;
}
