import { BusinessOpportunityAnalysis } from "../model/gpt";
import type { FC } from "react";

interface BusinessAnalyticProps {
  data: BusinessOpportunityAnalysis;
}

const BusinessAnalytic: FC<BusinessAnalyticProps> = ({ data }) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="grid grid-cols-2 gap-4">
       

        <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-xl font-bold">Perkiraan Modal</h2>
          <p className="mt-2">{data.estimated_capital}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-xl font-bold">Perkiraan ROI</h2>
          <p className="mt-2">{data.estimated_roi}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-xl font-bold">Perkiraan Break Even Point</h2>
          <p className="mt-2">{data.break_even_point}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-xl font-bold">ROI Timeframe</h2>
          <p className="mt-2">{data.roi_timeframe}</p>
        </div>
      </div>

      <div className="bg-white p-2 rounded-lg shadow">
        <h2 className="text-xl font-bold">Analisa Risiko</h2>
        <div>
          {data.risks.map((risk, index) => (
            <div
              key={index}
              className="bg-gray-50 p-4 rounded-lg shadow mb-4 last:mb-0"
            >
              <p className="text-xl font-bold">{risk.risk_description}</p>
              <h6 className="text-lg font-[500]">{risk.category}</h6>
              <p className="mt-2"><strong>Impact:</strong> {risk.impact}</p>
              <p className="mt-2"><strong>Likelihood:</strong> {risk.likelihood}</p>
              <span className="mt-2 w-fit flex flex-row px-2 py-1 rounded-lg text-xs" style={{backgroundColor: risk.risk_matrix_color, color: "white"}}>
                 {risk.risk_matrix_label} ({risk.risk_matrix})
              </span>
              <h6 className="text-lg font-[500] mt-2">Mitigasi Risiko</h6>
              <ul className="">
                {(risk?.mitigation?? []).map((mitigation, index) => (
                  <li key={index}>{mitigation}</li>
                ))}
              </ul>
              
            
            </div>
          ))}
        </div>
      </div>
      <div className="bg-white p-4 rounded-lg shadow">
        <h2 className="text-xl font-bold">Kesimpulan</h2>
        <blockquote className="mt-2">{data.conclusion}</blockquote>
      </div>
      <div className="bg-white p-4 rounded-lg shadow">
        <p className="text-xs font-bold italic">Catatan</p>
        <p className="mt-2 text-xs italic">
          <strong>Impact</strong> merujuk pada tingkat keparahan atau konsekuensi yang akan dialami organisasi jika suatu risiko terjadi, seperti kerugian finansial, kerusakan reputasi, atau gangguan operasional, sementara <strong>Likelihood</strong> adalah probabilitas atau kemungkinan terjadinya risiko tersebut.
        </p>
        <p className="mt-2 text-xs italic">
          Analisis dan angka-angka yang disajikan dalam laporan ini hanyalah perkiraan dan bukan kepastian. Hasil analisis ini dapat berbeda-beda tergantung pada asumsi, metode, dan data yang digunakan. Oleh karena itu, organisasi tidak boleh mengandalkan laporan ini sebagai satu-satunya acuan dalam mengambil keputusan.
        </p>
      </div>
     
    </div>
  );
};
export default BusinessAnalytic;
