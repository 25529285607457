import { FinancialGrowthAnalysis } from "../model/gpt";
import { money } from "../utils/helper";
import type { FC } from "react";

interface FinancialGrowthProps {
  data: FinancialGrowthAnalysis;
}

const FinancialGrowth: FC<FinancialGrowthProps> = ({ data }) => {
  return (
    <div className="flex flex-col">
      <div className="py-2">
        <h4 className="text-lg font-semibold">Financial Growth Analysis</h4>
      </div>
      <div className="py-2">
        <p className="text-sm font-light">{data.analysis}</p>
      </div>
      <div className="py-2">
        <h4 className="text-lg font-semibold">Projections</h4>
      </div>
      <div className="py-2">
        <table className="w-full">
          <thead>
            <tr>
              <th className="px-4">Year</th>
              <th className="px-4">Asset</th>
              <th className="px-4">Equity</th>
              <th className="px-4">Liability</th>
            </tr>
          </thead>
          <tbody>
            {data.projection.map((p) => (
              <tr key={p.year}>
                <td className="px-4">{p.year}</td>
                <td className="px-4">{money(p.assets, 2)}</td>
                <td className="px-4">{money(p.equity, 2)}</td>
                <td className="px-4">{money(p.liabilities, 2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="py-2">
        <h4 className="text-lg font-semibold">Recommendations</h4>
      </div>
      <div className="py-2">
        <ul className="list-disc list-inside text-sm font-light">
          {data.recommendations.map((r) => (
            <li key={r}>{r}</li>
          ))}
        </ul>
      </div>
      <div className="py-2">
        <h4 className="text-lg font-semibold">Risks</h4>
      </div>
      <div className="py-2">
        <ul className="list-disc list-inside text-sm font-light">
          {data.risks.map((r) => (
            <li key={r}>{r}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};
export default FinancialGrowth;
