// src/App.tsx
import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import {
  BsExclamationTriangleFill,
  BsPlusCircle,
  BsQuestionCircle,
  BsSendArrowUp,
  BsStopFill,
} from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { IoArrowDownOutline, IoPowerSharp } from "react-icons/io5";
import { MdOutlineAssistant } from "react-icons/md";
import { Link } from "react-router-dom";
import { Avatar, Button, Modal, SelectPicker } from "rsuite";
import "rsuite/dist/rsuite-no-reset.min.css";

import Swal from "sweetalert2";
import ChatBox from "./components/ChatBox";
import MainLayout from "./components/MainLayout";
import TypeEffect from "./components/type_effect";
import { CompanyCategory, CompanySimple } from "./model/company";
import { Profile } from "./model/profile";
import { chatMessage } from "./objects/chatMessage";
import { GPTResponse } from "./objects/gptResponse";
import { MdOutlineCleaningServices } from "react-icons/md";
import { IoMdArrowRoundUp } from "react-icons/io";
import { ImHeadphones } from "react-icons/im";

import {
  createCompany,
  getCompanyCategory,
  getMyPermissions,
} from "./repositories/company";
import {
  clearHistory,
  deleteHistory,
  getGPTHistories,
  sendPrompt,
  setBookmarkHistory,
} from "./repositories/gpt";
import {
  postLogin,
  postLoginGoogle,
  postRegisMiqro,
} from "./repositories/login";
import { getPermissions, getProfile } from "./repositories/my";
import {
  clearStorage,
  confirmDelete,
  getMyCompanies,
  getToken,
  initials,
  setMyCompanies,
  setNullString,
  setProfileStorage,
  setStorage,
  setStorageToken,
} from "./utils/helper";
import { errorToast, successToast } from "./utils/helper-ui";
import { blobUpload, fileUpload } from "./utils/file";
import { FileToUpload } from "./objects/file";
import HintBox from "./components/HintBox";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile, toBlobURL } from "@ffmpeg/util";
import {
  BiBookmark,
  BiSolidBookBookmark,
  BiSolidBookmark,
} from "react-icons/bi";

interface ChildHandle {
  updateState: () => void;
}

const App: React.FC = () => {
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const audioContextRef = useRef<AudioContext | null>(null);
  const analyserRef = useRef<AnalyserNode | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const childRef = useRef<ChildHandle>(null);
  const [mounted, setMounted] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const fileRef = useRef<HTMLInputElement>(null);
  const [phone, setPhone] = useState("");
  const [messages, setMessages] = useState<chatMessage[]>([]);
  const [messageFiltered, setMessageFiltered] = useState<chatMessage[]>([]);
  const [currentMessage, setCurrentMessage] = useState<chatMessage | null>(
    null
  );
  const [currentMessageRaw, setCurrentMessageRaw] =
    useState<GPTResponse | null>(null);
  const [showHints, setShowHints] = useState(false);

  const [input, setInput] = useState("");
  const [token, setToken] = useState("");
  const chatRef = useRef<HTMLTextAreaElement>(null);
  const [profile, setProfile] = useState<Profile | null>(null);
  const [permissions, setPermissions] = useState<string[]>([]);
  const [companies, setCompanies] = useState<CompanySimple[]>([]);
  const [companyShows, setCompanyShows] = useState<CompanySimple[]>([]);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [placeholder, setPlaceholder] = useState("Halo Jaraya...");
  const [isLoading, setIsLoading] = useState(false);
  const chatHistoryRef = useRef<HTMLDivElement>(null);
  const [displayTypeWriterMsg, setDisplayTypeWriterMsg] = useState("");
  const [modalRegistration, setModalRegistration] = useState(false);
  const [address, setAddress] = useState("");
  const [categoryCompanyId, setCategoryCompanyId] = useState("");
  const [categories, setCategories] = useState<CompanyCategory[]>([]);
  const [companyCategoryOther, setCompanyCategoryOther] = useState("");
  const [selectedCompany, setSelectedCompany] = useState<CompanySimple | null>(
    null
  );
  const [isWriting, setIsWriting] = useState(false);
  const [fileToUpload, setFileToUpload] = useState<FileToUpload | null>(null);
  const [voiceNote, setVoiceNote] = useState<FileToUpload | null>(null);
  const animationRef = useRef<number | null>(null);
  const ffmpegRef = useRef(new FFmpeg());
  const [isAtBottom, setIsAtBottom] = useState(false);
  const [isBookmarked, setIsBookmarked] = useState(false);

  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.style.height = "auto";

      const newHeight = Math.min(chatRef.current.scrollHeight, 80);
      chatRef.current.style.height = `${newHeight}px`;
    }
  }, [input]);

  useEffect(() => {
    if (mounted) {
      setTimeout(handleScroll, 300);
    }
  }, [mounted]);

  useEffect(() => {
    setMounted(true);
    getCompanyCategory()
      .then((v) => v.json())
      .then((v) => {
        setCategories(v.data);
        setCategoryCompanyId(v.data[0].uuid);
      });

    if (chatHistoryRef.current) {
      chatHistoryRef.current.addEventListener("scroll", showScrollToBotton);
    }

    return () => {
      if (chatHistoryRef.current) {
        chatHistoryRef.current.removeEventListener(
          "scroll",
          showScrollToBotton
        );
      }
    };
  }, []);

  const showScrollToBotton = () => {
    if (chatHistoryRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatHistoryRef.current;
      const isUserAtBottom = scrollHeight - scrollTop === clientHeight;
      setIsAtBottom(isUserAtBottom);
    }
  };

  const handleSend = async () => {
    if (input.trim() === "" && !voiceNote) return;
    if (!profile) {
      if (!username) {
        if (!validateEmailOrPhoneNumber(input)) {
          errorToast("Email / No Telp Tidak Valid");
          return;
        }
        setUsername(input);
        setMessages([
          ...messages,
          {
            text: `No Telp/ Email Anda: ${input}`,
            isUser: true,
            isFirst: false,
            type: "",
          },
        ]);
        setTimeout(() => {
          setInput("");
        }, 300);
        return;
      }
      if (username && !password) {
        setPassword(input);
        return;
      }
    }

    try {
      setIsLoading(true);
      // setInput("");
      // if (displayTypeWriterMsg != "") {
      //   setMessages([
      //     ...messages,
      //     {
      //       text: displayTypeWriterMsg,
      //       isUser: false,
      //       isFirst: false,
      //       createdAt: moment().toISOString(),
      //       type: "",
      //     },
      //     { text: input, isUser: true, isFirst: false, type: "" },
      //   ]);
      //   setDisplayTypeWriterMsg("");
      // } else {
      //   setMessages([
      //     ...messages,
      //     { text: input, isUser: true, isFirst: false, type: "", uuid: "" },
      //   ]);
      // }
      let url = "";
      let fileType = "";
      if (fileToUpload) {
        url = fileToUpload.url;
        fileType = fileToUpload.mimeType?.replaceAll("image/", "") ?? "jpg";
      }
      if (voiceNote) {
        url = voiceNote.url;
        fileType = voiceNote.mimeType ?? "audio/mpeg";
      }
      let resp = await sendPrompt(input, url, fileType);
      let respJson = await resp.json();
      // setDisplayTypeWriterMsg(respJson.data.answer);
      setCurrentMessageRaw(respJson.data);
      setCurrentMessage({
        text: respJson.data.answer,
        isUser: false,
        isFirst: false,
        uuid: respJson.data.uuid,
        data: respJson.data.data,
        createdAt: respJson.data.created_at,
        isSelectCompany: false,
        type: respJson.data.response_type,
        lastId: respJson.data.last_id,
      });
      setMessages([
        ...messages.filter((e) => e.uuid != ""),
        {
          text: respJson.data?.question ?? "",
          isUser: true,
          isFirst: false,
          uuid: respJson.data?.uuid,
          createdAt: respJson.data?.created_at,
          isSelectCompany: false,
          type: respJson.data?.response_type ?? "",
          lastId: respJson.data?.last_id,
          fileUrl: respJson.data?.file_url,
          fileType: respJson.data?.file_type,
        },
        {
          text: respJson.data.answer,
          isUser: false,
          isFirst: false,
          uuid: respJson.data.uuid,
          data: respJson.data.data,
          createdAt: respJson.data.created_at,
          isSelectCompany: false,
          type: respJson.data.response_type,
          lastId: respJson.data.last_id,
        },
      ]);
      setFileToUpload(null);
      setVoiceNote(null);
      setIsRecording(false);
      setTimeout(() => {
        setInput("");
      }, 300);
    } catch (error) {
      errorToast(`${error}`);
    } finally {
      setIsLoading(false);
    }
    // Call API to get response here and add to messages
  };

  useEffect(() => {
    if (voiceNote) {
      // console.log("voiceNote", voiceNote)
      handleSend();
    }
  }, [voiceNote]);

  useEffect(() => {
    if (username && password) {
      postLoginReq();
    }
  }, [username, password]);

  const onSuccessGoogle = async (val: CredentialResponse) => {
    try {
      setIsLoading(true);
      var resp = await postLoginGoogle({
        token: val.credential!,
        device: "web",
        fcm_token: "",
      });
      var respJson = await resp.json();
      setProfile(respJson.user);
      // setStorage({token: respJson.token, permissions: respJson.permissions})
      setToken(respJson.token);
      setPermissions(respJson.permissions);
      setCompanies(respJson.companies);
      setMyCompanies(respJson.companies);

      setPlaceholder("Halo, Asya ....");
    } catch (error) {
      Swal.fire("Perhatian", `${error}`, "error");
    } finally {
      setIsLoading(false);
    }
  };

  let postLoginReq = async () => {
    // console.log(email, password)
    try {
      setIsLoading(true);
      var resp = await postLogin({
        username: username,
        password,
        device: "web",
        fcm_token: "",
      });
      var respJson = await resp.json();
      setInput("");
      setMessages([]);
      setProfile(respJson.user);
      // setStorage({token: respJson.token, permissions: respJson.permissions})
      setToken(respJson.token);
      setPermissions(respJson.permissions);
      setCompanies(respJson.companies);
      setMyCompanies(respJson.companies);
      setPlaceholder("Halo, Asya ....");
    } catch (error) {
      // console.log(error)
      setPassword("");
      Swal.fire("Perhatian", `${error}`, "error");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllGptHistories(false);
  }, [isBookmarked]);
  const getAllGptHistories = (seperate: boolean) => {
    if (!profile) return;
    if (!selectedCompany) return;
    getGPTHistories(isBookmarked)
      .then((v) => v.json())
      .then((v) => {
        let results: GPTResponse[] = [...v.data];
        let data: chatMessage[] = [];

        for (let index = 0; index < results.length; index++) {
          const e = results[index];
        }
        for (const e of results) {
          data.push({
            text: e.answer,
            text_md: e.answer_md,
            isUser: false,
            isFirst: false,
            uuid: e.uuid,
            data: e.data,
            createdAt: e.created_at,
            isSelectCompany: false,
            type: e.response_type,
            lastId: e.last_id,
            fileUrl: e.file_url,
            fileType: e.file_type,
            is_bookmarked: e.is_bookmarked,
          });
          data.push({
            text: e.question,
            isUser: true,
            isFirst: false,
            uuid: e.uuid,
            createdAt: e.created_at,
            isSelectCompany: false,
            type: e.response_type,
            lastId: e.last_id,
            fileUrl: e.file_url,
            fileType: e.file_type,
            is_bookmarked: e.is_bookmarked,
          });
        }
        data.reverse();
        setMessages(data);
      });
  };

  useEffect(() => {
    setMessageFiltered(
      messages.filter((e) => {
        if (profile) {
          return !e.isFirst;
        }
        return e;
      })
    );
  }, [messages]);

  useEffect(() => {
    handleScroll();
  }, [displayTypeWriterMsg]);

  useEffect(() => {
    // handleScroll();
  }, [messageFiltered]);

  useEffect(() => {
    for (const c of companyShows) {
      setMessages([
        ...messages,
        {
          text: c.name,
          uuid: c.uuid,
          isSelectCompany: true,
          isUser: false,
          isFirst: false,
          type: "",
        },
      ]);
    }
  }, [companyShows]);

  useEffect(() => {
    if (!mounted) return;
    if (!profile) {
      if (!username) {
        setPlaceholder("Masukan Email atau No. Telp anda ... ");
      }
      if (username && !password) {
        setPlaceholder("Masukan password anda ... ");
      }
      if (!profile && !selectedCompany)
        setMessages([
          {
            text: "Halo, selamat datang di Asisten Pribadi Jaraya, anda belum login silakan kirim email atau no-telp anda,",
            isUser: false,
            isFirst: true,
            type: "",
          },
        ]);
    } else {
      // console.log(profile)
      getAllGptHistories(false);
    }
    if (profile && selectedCompany) {
      setPlaceholder("Hallo Asya ....");
    }
  }, [profile, username, password, mounted, selectedCompany]);

  const validateEmailOrPhoneNumber = (s: string) => {
    if (/^(\+62|62)?[\s-]?0?8[1-9]{1}\d{1}[\s-]?\d{4}[\s-]?\d{2,5}$/.test(s)) {
      return true;
    } else {
      return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(s);
    }
  };

  let handleScroll = () => {
    if (chatHistoryRef.current) {
      const element = chatHistoryRef.current;
      const scrollTop = element.scrollHeight - element.clientHeight;
      element.scrollTo({
        top: scrollTop,
        behavior: "smooth",
      });

      // const isUserAtBottom = element.scrollHeight - scrollTop === element.clientHeight;
      // setIsAtBottom(isUserAtBottom);
    }
  };

  const postRegistration = async () => {
    try {
      setIsLoading(true);
      await postRegisMiqro({
        phone: phone,
        email: email,
        full_name: name,
        password: password,
        company_name: companyName,
        company_address: companyAddress,
        affiliate_code: "",
        verification_mode: "asya",
      });

      successToast("Berhasil, Silakan verifikasi akun anda sebelum Login");

      setTimeout(() => {
        window.location.href = "/";
      }, 2000);
    } catch (error) {
      errorToast(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  const selectCompany = async (e: CompanySimple) => {
    // await setStorage({ token, permissions, company: e })
    try {
      setIsLoading(true);
      var resp = await getPermissions(token, e.uuid);

      var respJson = await resp.json();
      setPermissions(respJson.data);
      await setStorage({ token, permissions: respJson.data, company: e });
      var profile = await getProfile();
      var profileJson = await profile.json();
      // console.log("profileJson", profileJson.data)
      await setProfileStorage(profileJson.data);
      setProfile(profileJson.data);
      setCompanies([]);
      setMessages([
        {
          text: "Halo! Apa kabar? Ada yang bisa saya bantu hari ini?",
          isUser: false,
          isFirst: true,
          type: "",
        },
      ]);
      window.location.href = "/";
    } catch (error) {
      Swal.fire("Perhatian", `${error}`, "error");
    } finally {
      setIsLoading(false);
    }
  };

  const addCompany = async () => {
    try {
      await setStorageToken(token);
      setIsLoading(true);
      var resp = await createCompany({
        name,
        phone: setNullString(phone),
        address: setNullString(address),
        email: setNullString(email),
        fax: setNullString(""),
        contact_person: setNullString(""),
        contact_person_position: setNullString(""),
        is_active: false,
        is_professional: false,
        is_default: true,
        category_company_id: setNullString(categoryCompanyId),
        api_secret: "",
        tax_payer_number: "",
        currency_actives: "[]",
        category_company_other: companyCategoryOther,
        multiple_currency: false,
      });
      var respJson = await resp.json();

      await setMyCompanies([respJson.data]);
      await setStorage({ token, permissions: [], company: respJson.data });
      var permissions = await getMyPermissions();
      var permissionsJson = await permissions.json();
      setPermissions(permissionsJson.data);
      await setStorage({
        token,
        permissions: permissionsJson.data,
        company: respJson.data,
      });
      var profile = await getProfile();
      var profileJson = await profile.json();
      // console.log("profileJson", profileJson.data)
      setProfileStorage(profileJson.data);
      window.location.href = "/";
    } catch (error) {
      await setStorageToken(token);
      Swal.fire("Perhatian", `${error}`, "error");
    } finally {
      setIsLoading(false);
    }
  };

  const renderTopBar = () => {
    if (profile && selectedCompany)
      return (
        <div className="bg-white px-4 py-2 flex flex-row justify-between items-center">
          <div className="flex items-center">
            <Avatar
              src={profile.picture}
              alt={initials(profile.full_name)}
              circle
              size="sm"
            />
            <div
              className="flex flex-col cursor-pointer"
              onClick={async () => {
                let token = await getToken();
                let companies = await getMyCompanies();
                setToken(token);
                setCompanies(companies);
                setTimeout(() => {
                  handleScroll();
                }, 300);
              }}
            >
              <span className="ml-2 text-sm font-[600]">
                {profile.full_name}
              </span>
              <span className="ml-2 text-xs">{selectedCompany.name}</span>
            </div>
          </div>
          <div className="flex flex-row gap-2">
            {isBookmarked ? (
              <BiSolidBookmark
                className="cursor-pointer text-pink-600"
                size={18}
                onClick={() => setIsBookmarked(false)}
              />
            ) : (
              <BiBookmark
                className="cursor-pointer"
                size={18}
                onClick={() => setIsBookmarked(true)}
              />
            )}
            <BsQuestionCircle size={18} onClick={() => setShowHints(true)} />
            <MdOutlineCleaningServices
              size={18}
              className="cursor-pointer hover:text-red-400"
              onClick={() => {
                confirmDelete(
                  () => {
                    clearHistory().then((v) => getAllGptHistories(false));
                  },
                  null,
                  "Bersihkan History Chat"
                );
              }}
            />
            <IoPowerSharp
              size={18}
              className="cursor-pointer hover:text-red-400"
              onClick={() => {
                clearStorage();
                setProfile(null);
                setSelectedCompany(null);
                setInput("");
                setUsername("");
                setPassword("");
                window.location.href = "/";
              }}
            />
          </div>
        </div>
      );
    return <div></div>;
  };

  const renderShoutbox = () => (
    <div className="bg-transparent p-2 flex flex-row gap-2" style={{
      zIndex: 999
    }}>
      <div className="relative flex flex-1 max-h-[100px]">
        {!profile ? (
          <input
            type={!profile && username ? "password" : "email"}
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder={placeholder}
            onKeyUp={(val) => {
              if (val.key == "Enter" && input.length > 0) {
                handleSend();
              }
            }}
            className="w-full p-2 pl-10 pr-10 text-sm outline-none text-gray-700  border rounded-full  leading-5 max-h-[100px]"
          />
        ) : (
          <textarea
            disabled={isRecording}
            ref={chatRef}
            rows={1}
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder={placeholder}
            onKeyUp={(val) => {
              if (val.key == "Enter" && input.length > 0 && !val.shiftKey) {
                handleSend();
              }
              // if (val.key == "Enter" && chatInput.length > 0) {
              //
              // }
            }}
            className="w-full p-2 pl-10 pr-10 text-sm outline-none text-gray-700  border rounded-xl  leading-5 max-h-[100px]"
          />
        )}
        <BsPlusCircle
          size={18}
          className="absolute top-2.5 left-2 w-6"
          onClick={() => {
            fileRef.current?.click();
          }}
        />
        <div className=" rounded-full bg-pink-600 absolute top-1 right-1 w-7 h-7 flex justify-center items-center">
          {isWriting || isLoading || isRecording ? (
            <BsStopFill
              className=" cursor-pointer text-white "
              size={12}
              onClick={() => {
                if (isRecording) {
                  stopRecording();
                  return;
                }
                if (isLoading) {
                  return;
                }
                setMessages([
                  ...messages.filter((e) => e.uuid != ""),
                  {
                    text: currentMessageRaw?.question ?? "",
                    isUser: true,
                    isFirst: false,
                    data: currentMessageRaw?.data,
                    uuid: currentMessageRaw?.uuid,
                    createdAt: currentMessageRaw?.created_at,
                    isSelectCompany: false,
                    type: currentMessageRaw?.response_type ?? "",
                    lastId: currentMessageRaw?.last_id,
                  },
                  currentMessage!,
                ]);

                setDisplayTypeWriterMsg("");
                setTimeout(() => {
                  setCurrentMessage(null);
                  setIsWriting(false);
                }, 300);
              }}
            />
          ) : (
            <>
              {input.length == 0 && profile ? (
                <ImHeadphones
                  size={12}
                  className=" cursor-pointer text-white "
                  onClick={() => {
                    startRecording();
                  }}
                />
              ) : (
                <IoMdArrowRoundUp
                  size={12}
                  className=" cursor-pointer text-white "
                  onClick={() => {
                    handleSend();
                  }}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );

  const convertToMP3 = async (audioBlob: Blob): Promise<string> => {
    setIsLoading(true);
    const ffmpeg = ffmpegRef.current;
    // ffmpeg.on('log', ({ message }) => {
    //     console.log(message);
    // });
    await ffmpeg.load();
    await ffmpeg.writeFile("audio.wav", await fetchFile(audioBlob));
    await ffmpeg.exec(["-i", "audio.wav", "audio.mp3"]);
    const data = await ffmpeg.readFile("audio.mp3");

    setIsLoading(false);

    return URL.createObjectURL(new Blob([data], { type: "audio/mpeg" }));
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      // Setup Web Audio API
      audioContextRef.current = new AudioContext();
      const source = audioContextRef.current.createMediaStreamSource(stream);
      analyserRef.current = audioContextRef.current.createAnalyser();
      source.connect(analyserRef.current);

      mediaRecorderRef.current = new MediaRecorder(stream);
      mediaRecorderRef.current.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };
      mediaRecorderRef.current.onstop = async () => {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: "audio/wav",
        });
        const audioUrl = await convertToMP3(audioBlob);
        // const audioUrl = URL.createObjectURL(audioBlob);
        setAudioUrl(audioUrl);
        if (audioUrl) {
          let blob = await fetch(audioUrl).then((r) => r.blob());
          // Kirim voice note ke server atau lakukan apa pun yang Anda perlukan
          uploadVoiceNote(blob);
        }

        audioChunksRef.current = [];
      };
      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };

  useEffect(() => {
    if (isRecording) {
      setTimeout(() => {
        visualize();
      }, 300);
    }
  }, [isRecording]);

  const uploadVoiceNote = (blob: Blob) => {
    blobUpload("File/Blob", {
      blob,
      dir: "user",
    })
      .then((v) => v.json())
      .then((v) => {
        setVoiceNote({
          url: v.data.url,
          path: v.data.url,
          mimeType: "audio/mpeg",
        });
      });
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      console.log("stopRecording");
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
      // sendVoiceNote()
    }
  };

  const visualize = () => {
    if (analyserRef.current && canvasRef.current) {
      const canvas = canvasRef.current;
      const canvasCtx = canvas.getContext("2d");
      if (!canvasCtx) return;

      const bufferLength = analyserRef.current.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      const draw = () => {
        analyserRef.current?.getByteTimeDomainData(dataArray);

        canvasCtx.fillStyle = "rgb(248,248,249)";
        canvasCtx.fillRect(0, 0, canvas.width, canvas.height);

        canvasCtx.lineWidth = 2;
        canvasCtx.strokeStyle = "rgb(213,38,116)";

        canvasCtx.beginPath();

        const sliceWidth = canvas.width / bufferLength;
        let x = 0;

        for (let i = 0; i < bufferLength; i++) {
          const v = dataArray[i] / 128.0;
          const y = (v * canvas.height) / 2;

          if (i === 0) {
            canvasCtx.moveTo(x, y);
          } else {
            canvasCtx.lineTo(x, y);
          }

          x += sliceWidth;
        }

        canvasCtx.lineTo(canvas.width, canvas.height / 2);
        canvasCtx.stroke();

        animationRef.current = requestAnimationFrame(draw);
      };

      draw();
    }
  };

  const sendVoiceNote = async () => {
    console.log("sendVoiceNote");
    if (audioUrl) {
    }
  };

  return (
    <MainLayout
      onLoadProfile={(v) => {
        setProfile(v);
      }}
      onLoadCompany={(v) => {
        setSelectedCompany(v);
      }}
    >
      <div className="h-full  flex flex-col relative">
        <div
          onClick={handleScroll}
          className={`${
            isAtBottom ? "fade-out" : "fade-in"
          } rounded-full bg-pink-300 absolute bottom-14 right-4 w-5 h-5 flex justify-center items-center z-50 cursor-pointer`}
        >
          <IoArrowDownOutline className="text-white" />
        </div>
        {renderTopBar()}

        <div
          className="flex-1 overflow-y-auto  flex flex-col  p-4 history-chat"
          ref={chatHistoryRef}
        >
          {companies.length == 0 && profile && !selectedCompany && (
            <div>
              <p className="text-xl font-semibold">Isi Data Perusahaan</p>
              <div className="mb-4">
                <p className="mb-1">Nama Perusahaan</p>
                <input
                  className="form-control2"
                  type="text"
                  placeholder="Nama Perusahaan"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <p className="mb-1">Kategori Perusahaan</p>
                <SelectPicker
                  className="mb-2 rounded-lg"
                  block
                  labelKey="name"
                  valueKey="uuid"
                  data={[...categories, { uuid: "", name: "Kategori Lainnya" }]}
                  value={categoryCompanyId}
                  onChange={(val) => setCategoryCompanyId(val ?? "")}
                />
                {categoryCompanyId == "" && (
                  <input
                    className="form-control2"
                    type="text"
                    placeholder={"Tulis Nama Kategori Perusahaan"}
                    value={companyCategoryOther}
                    onChange={(el) => setCompanyCategoryOther(el.target.value)}
                  />
                )}
              </div>
              <div className="mb-4">
                <p className="mb-1">Email</p>
                <input
                  className="form-control2"
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <p className="mb-1">Telp</p>
                <input
                  className="form-control2"
                  type="text"
                  placeholder="Telp"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <p className="mb-1">Alamat</p>
                <textarea
                  className="desc2 form-control2"
                  placeholder="Alamat"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
              <div className="flex">
                <button
                  onClick={addCompany}
                  className="flex min-w-[84px] max-w-[480px] cursor-pointer items-center justify-center overflow-hidden rounded-full h-10 px-4 flex-1 bg-[#f5d33d] text-[#181711] text-sm font-bold leading-normal tracking-[0.015em]"
                >
                  <span className="truncate">Simpan</span>
                </button>
              </div>
            </div>
          )}

          <ChatBox
            onHint={(v) => {
              setInput(v.text);
              if (v.url) {
                setFileToUpload({
                  url: v.url,
                  path: v.url,
                  mimeType: "image/jpg",
                });
              }
            }}
            messages={messageFiltered}
            profile={profile!}
            onCopy={(val) => {
              setInput(val);
            }}
            onLoading={(val) => {
              setIsLoading(val);
            }}
            onDelete={(msg) => {
              deleteHistory(msg.uuid!).then(() => getAllGptHistories(false));
              // confirmDelete(() => {
              //   deleteHistory(msg.uuid!)
              //     .then(() => getAllGptHistories(false))
              // })
            }}
            onBookmark={(msg) => {
              setBookmarkHistory(msg.uuid!).then(() => {
                setMessages([
                  ...messages.map((e) => {
                    if (e.uuid == msg.uuid) {
                      e.is_bookmarked = !msg.is_bookmarked;
                    }
                    return e;
                  }),
                ]);
              });
            }}
          />

          {displayTypeWriterMsg && (
            <TypeEffect
              text={displayTypeWriterMsg}
              onTyping={() => {
                setIsWriting(true);
                handleScroll();
              }}
              onFinished={() => {
                setMessages([
                  ...messages.filter((e) => e.uuid != ""),
                  {
                    text: currentMessageRaw?.question ?? "",
                    isUser: true,
                    isFirst: false,
                    uuid: currentMessageRaw?.uuid,
                    createdAt: currentMessageRaw?.created_at,
                    isSelectCompany: false,
                    type: currentMessageRaw?.response_type ?? "",
                    lastId: currentMessageRaw?.last_id,
                    fileUrl: currentMessageRaw?.file_url,
                    fileType: currentMessageRaw?.file_type,
                  },
                  currentMessage!,
                ]);

                setDisplayTypeWriterMsg("");
                setTimeout(() => {
                  setCurrentMessage(null);
                  setIsWriting(false);
                }, 300);
              }}
            />
          )}
          {companies.length > 0 && (
            <div
              className={`mb-4 p-4 rounded-lg bg-white text-gray-700 border self-start`}
            >
              <strong>Pilih Perusahaan:</strong>
              <ul className=" list-disc pl-4">
                {companies.map((e) => (
                  <li
                    key={e.uuid}
                    className=" hover:font-semibold hover:text-sky-500 cursor-pointer"
                    onClick={() => selectCompany(e)}
                  >
                    {e.name}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        {!profile && (
          <div className="px-4">
            <div
              className={`min-w-[40%] max-w-[90%] relative mb-4 p-3 rounded-lg bg-white text-gray-700 border self-start answer-wrapper`}
            >
              <div className=" flex flex-row gap-2">
                <BsExclamationTriangleFill
                  size={16}
                  className="text-yellow-400"
                />
                <div>
                  <span>
                    Belum punya akun{" "}
                    <Link target="_blank" to="https://jaraya.id">
                      Jaraya?
                    </Link>
                  </span>{" "}
                  <span>
                    Daftar{" "}
                    <span
                      className=" text-blue-400 cursor-pointer font-[600]"
                      onClick={() => {
                        setModalRegistration(true);
                      }}
                    >
                      {" "}
                      Disini
                    </span>{" "}
                    atau
                  </span>
                </div>
              </div>
              <div className="flex justify-center mt-4">
                <GoogleLogin
                  onSuccess={onSuccessGoogle}
                  onError={() => {
                    console.log("Login Failed");
                  }}
                />
              </div>
            </div>
          </div>
        )}

        {isLoading && (
          <div className="flex justify-center items-center">
            <img src="/loading.gif" alt="" className="w-16" />
          </div>
        )}
        {isRecording && (
          <>
            <div className="flex justify-center items-center">
              <canvas ref={canvasRef} width="400" height="60"></canvas>
            </div>
            <div className="flex justify-center items-center py-2">
              <div className="cursor-pointer bg-pink-600 rounded-full p-4">
                <BsStopFill
                  className="text-white"
                  size={28}
                  onClick={() => stopRecording()}
                />
              </div>
            </div>
          </>
        )}

        {!isRecording && renderShoutbox()}
        {fileToUpload && (
          <div className="absolute bg-black top-0 left-0 right-0 bottom-0">
            <div className=" absolute top-2 left-2 p-2 bg-gray-800 rounded-full">
              <AiOutlineClose
                className="text-white"
                onClick={() => setFileToUpload(null)}
              />
            </div>
            <img src={fileToUpload.url} className=" object-fill w-full" />
            {isLoading ? (
              <div className="absolute bottom-2 left-4 right-4 flex justify-center">
                <img src="/loading.gif" alt="" className="w-16 z-50 " />
              </div>
            ) : (
              <div className="absolute bottom-2 left-4 right-4 flex justify-center">
                <input
                  value={input}
                  onChange={(val) => setInput(val.target.value)}
                  type="text"
                  className="bg-gray-100 rounded-2xl py-2 px-4 pr-10 outline-none w-full"
                  placeholder="Tuliskan Pesan"
                />

                <BsSendArrowUp
                  className="text-gray-600 top-3 right-3 absolute"
                  onClick={handleSend}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <Modal
        size={"xs"}
        open={modalRegistration}
        onClose={() => setModalRegistration(false)}
      >
        <Modal.Header>
          <Modal.Title>Daftar Jaraya</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="flex max-w-[480px] flex-wrap items-end gap-4 px-4 py-3">
              <label className="flex flex-col min-w-40 flex-1">
                <p className="text-[#0e171b] text-base font-medium leading-normal pb-2">
                  Nama Lengkap
                </p>
                <input
                  type="text"
                  placeholder="Nama Lengkap"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </label>
            </div>
            <div className="flex max-w-[480px] flex-wrap items-end gap-4 px-4 py-3">
              <label className="flex flex-col min-w-40 flex-1">
                <p className="text-[#0e171b] text-base font-medium leading-normal pb-2">
                  No WA / Email
                </p>
                <input
                  type="text"
                  placeholder="No WA / Email"
                  className="form-control"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </label>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="w-full text-xl"
            appearance="primary"
            color="orange"
            onClick={postRegistration}
          >
            Kirim
          </Button>
        </Modal.Footer>
      </Modal>
      <input
        accept="image/*"
        type="file"
        className="hidden"
        ref={fileRef}
        onChange={async (el) => {
          let files = fileRef.current?.files;
          if (files) {
            try {
              setIsLoading(true);
              var resp = await fileUpload(`File/ImageUpload`, {
                image: el.target.files![0],
                dir: "user",
              });

              let respJSON = await resp.json();
              setFileToUpload({
                path: respJSON.data.path,
                url: respJSON.data.url,
                mimeType: files[0].type,
              });
            } catch (error) {
              errorToast(`${error}`);
            } finally {
              setIsLoading(false);
            }
          }
        }}
      />
      <Modal size={"xs"} open={showHints} onClose={() => setShowHints(false)}>
        <Modal.Body>
          <HintBox
            onClick={(v) => {
              setInput(v.text);
              if (v.url) {
                setFileToUpload({
                  url: v.url,
                  path: v.url,
                  mimeType: "image/jpg",
                });
              }
              setShowHints(false);
            }}
          />
        </Modal.Body>
      </Modal>
    </MainLayout>
  );
};

export default App;
