import { CashFlowHealthRateScore } from "../model/gpt";
import type { FC } from "react";
import { money } from "../utils/helper";

interface CashFlowHealthProps {
    data: CashFlowHealthRateScore;
  }
  
  const CashFlowHealth: FC<CashFlowHealthProps> = ({ data }) => {
    return (
      <div className="p-4 bg-white shadow rounded-lg">
        <h2 className="text-xl font-semibold mb-2">CashFlow Health Rate Score</h2>
        <p className="text-xs">{data.description}</p>
        <h3 className="text-xl mt-4">Data Perbulan</h3>
        <table className="table-auto w-full">
          <thead>
            <tr>
              <th className="px-4 py-2">Bulan</th>
              <th className="px-4 py-2">Pemasukan</th>
              <th className="px-4 py-2">Pengeluaran</th>
              <th className="px-4 py-2">Surplus / Defisit</th>
            </tr>
          </thead>
          <tbody>
            {data.monthly_data.map((e, i) => (
              <tr key={i}>
                <td className="px-4 py-2">{e.month}</td>
                <td className="px-4 py-2">{money(e.income, 0)}</td>
                <td className="px-4 py-2">{money(e.expense, 0)}</td>
                <td className="px-4 py-2">{money(e.surplus_deficit, 0)}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="grid grid-cols-2 gap-4 mt-4">
          <div>
            <p className="text-normal font-semibold">Total Cash In</p>
            <p className="text-green-600">{money(data.total_income, 2)}</p>
          </div>
          <div>
            <p className="text-normal font-semibold">Total Cash Out</p>
            <p className="text-red-600">{money(data.total_expense, 2)}</p>
          </div>
          <div>
            <p className="text-normal font-semibold">Total Surplus/Deficit</p>
            <p
              className={`text-${
                data.total_surplus_deficit >= 0 ? "green" : "red"
              }-600`}
            >
              {money(data.total_surplus_deficit, 2)}
            </p>
          </div>
          <div>
            <p className="text-normal font-semibold">Score</p>
            <p>{money(data.cashflow_health_score, 2)}%</p>
            <div
              className={`p-2  rounded-lg ${
                data.cashflow_health_score < 0
                  ? "bg-red-200"
                  : data.cashflow_health_score < 10
                  ? "bg-yellow-200"
                  : data.cashflow_health_score < 25
                  ? "bg-green-200"
                  : "bg-green-400"
              }`}
            >
              <p>{data.category}</p>
            </div>
          </div>
          <div className="col-span-2">
            <p className="text-normal font-semibold">Recommendation</p>
            <ul className="list-disc list-outside">
              {data.recommendation.map((rec, index) => (
                <li key={index}>{rec}</li>
              ))}
            </ul>
          </div>
        </div>
        <h3 className="text-xl mt-4">Forecast</h3>
        <table className="table-auto w-full">
          <thead>
            <tr>
              <th className="px-4 py-2">Bulan</th>
              <th className="px-4 py-2">Surplus / Defisit</th>
            </tr>
          </thead>
          <tbody>
            {data.cashflow_prediction.next_predictions.map((e, i) => (
              <tr key={i}>
                <td className="px-4 py-2">{e.month}</td>
                <td className="px-4 py-2">
                  {money(e.predicted_surplus_deficit, 0)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="col-span-2 mt-4">
          <p className="text-normal font-semibold">Potential Risks</p>
          <ul className="list-disc list-outside">
            {data.cashflow_prediction.potential_risks.map((rec, index) => (
              <li key={index}>{rec}</li>
            ))}
          </ul>
        </div>
        <div className="col-span-2 mt-4">
          <p className="text-normal font-semibold">Mitigation Strategies</p>
          <ul className="list-disc list-outside">
            {data.cashflow_prediction.mitigation_strategies.map((rec, index) => (
              <li key={index}>{rec}</li>
            ))}
          </ul>
        </div>
      </div>
    );
  };
  export default CashFlowHealth;
  