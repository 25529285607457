import { DebtRiskAnalysis } from "../model/gpt";
import { money } from "../utils/helper";
import type { FC } from "react";

interface DebtRiskAnalysisComponentProps {
  data: DebtRiskAnalysis;
}

const DebtRiskAnalysisComponent: FC<DebtRiskAnalysisComponentProps> = ({
  data,
}) => {
  return (
    <div>
      <h3 className="font-bold text-xl">Debt Risk Analysis</h3>
      <p>
        <strong>Analysis:</strong> {data.analysis}
      </p>

      <p>
        <strong>Monthly Payment:</strong>
        <span className="text-blue-600">{money(data.debt_schedule.monthly_payment)}</span>
      </p>
      <p>
        <strong>Operational Cashflow:</strong>{" "}
        <span className="text-red-600">{money(data.financial_data.monthly_operational_cashflow)}</span>
      </p>
      <p>
        <strong>Total Liabilities:</strong>{" "}
        <span className="text-orange-600">{money(data.financial_data.total_liabilities)}</span>
      </p>
      <p>
        <strong>Recommendation:</strong> {data.recommendation}
      </p>
      <h3>Risks</h3>
      <ol className="list-none list-inside">
        {data.risks.map((risk, index) => (
          <li key={index} className="mb-4 border-b last:border-b-0 pb-4">
            {risk.risk_description}
              <div className="mt-2 font-semibold">Mitigation:</div>
              <ul className="ml-4">
                {risk.mitigation.map((mitigation, index) => (
                  <li key={index}>{mitigation}</li>
                ))}
              </ul>
          </li>
        ))}
      </ol>
    </div>
  );
};
export default DebtRiskAnalysisComponent;
