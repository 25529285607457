import { FinancialHealthRatios } from "../model/gpt";
import type { FC } from "react";

interface FinancialHealthProps {
  data: FinancialHealthRatios;
}

const FinancialHealth: FC<FinancialHealthProps> = ({ data }) => {
  return (
    <div className="">
      <div className="bg-white p-4 rounded-lg shadow">
        <h2 className="font-bold text-lg mb-2">Balance Sheet Data</h2>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <p className="text-sm">Current Assets</p>
            <p className="font-bold text-green-600">
              {data.balance_sheet_data.assets.current_assets.toLocaleString()}
            </p>
          </div>
          <div>
            <p className="text-sm">Non Current Assets</p>
            <p className="font-bold text-green-600">
              {data.balance_sheet_data.assets.non_current_assets.toLocaleString()}
            </p>
          </div>
          <div>
            <p className="text-sm">Equity</p>
            <p className="font-bold">
              {data.balance_sheet_data.equity.toLocaleString()}
            </p>
          </div>
          <div>
            <p className="text-sm">Current Liabilities</p>
            <p className="font-bold text-red-600">
              {data.balance_sheet_data.liabilities.current_liabilities.toLocaleString()}
            </p>
          </div>
          <div>
            <p className="text-sm">Non Current Liabilities</p>
            <p className="font-bold text-red-600">
              {data.balance_sheet_data.liabilities.non_current_liabilities.toLocaleString()}
            </p>
          </div>
        </div>
      </div>
      <div className="bg-white p-4 rounded-lg shadow">
        <h2 className="font-bold text-lg mb-2">Ratios</h2>
        <div className="">
          {data.ratios.map((ratio, index) => (
            <div key={index} className="mb-4 border-b border-gray-200 last:border-b-0 pb-4">
              <p className="text-sm">
                <strong>{ratio.ratio_name} :</strong>{" "}
                {ratio.value.toLocaleString()}
              </p>
              <p className="text-xs">{ratio.interpretation}</p>
              <h5 className="text-sm font-semibold mt-4">Risk</h5>
              <p className="text-xs">{ratio.risk}</p>
              <h5 className="text-sm font-semibold mt-4">Recommendations</h5>
              <p className="text-xs">{ratio.recommendation}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default FinancialHealth;
