import { IdentifyUnusualTransactions } from "../model/gpt";
import { money } from "../utils/helper";
import type { FC } from "react";
import Moment from "react-moment";

interface IdentifyUnusualProps {
  data: IdentifyUnusualTransactions;
}

const IdentifyUnusual: FC<IdentifyUnusualProps> = ({ data }) => {
  return (
    <div>
      <table className="table-auto w-full">
        <thead>
          <tr>
            <th className="px-4 py-2">Tgl</th>
            <th className="px-4 py-2">Jumlah</th>
            <th className="px-4 py-2">Keterangan</th>
          </tr>
        </thead>
        <tbody>
          {data.unusual_transactions.map((e, i) => (
            <tr key={i} className="align-top border-b">
              <td className="px-4 py-2">
                <Moment format="DD MMM YYYY">{e.date}</Moment>
              </td>
              <td className="px-4 py-2">{money(e.amount)}</td>
              <td className="px-4 py-2">
                <p className="font-semibold">Transaksi</p>
                {e.description}
                <p className="font-semibold">Penjelasan</p>
                {e.explanation}
                <p className="font-semibold">Rekomendasi</p>
                {e.recommendation}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <h4 className="text-normal mt-4">Overall Assessment:</h4>
      <p>{data.overall_assessment}</p>
      <h4 className="text-normal mt-4">Overall Recommendation:</h4>
      <p>{data.overall_recommendation}</p>
    </div>
  );
};
export default IdentifyUnusual;
