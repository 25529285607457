import type { FC } from "react";
import { AssetEfficiencyAnalysis } from "../model/gpt";
import { money } from "../utils/helper";

interface AssetEfficiencyProps {
  data: AssetEfficiencyAnalysis;
}

const AssetEfficiency: FC<AssetEfficiencyProps> = ({ data }) => {
    return (
      <div className="asset-efficiency">
        <h2 className="font-bold text-xl mb-4">Asset Efficiency Analysis</h2>
        <p>{data.analysis}</p>
        <p>
          <strong>Debt Schedule Monthly Payment:</strong>{" "}
          {money(data?.debt_schedule?.monthly_payment)}
        </p>
        <p>
          <strong>Total Liabilities:</strong>{" "}
          {money(data?.financial_data?.total_liabilities)}
        </p>
        <p>
          <strong>Monthly Operational Cashflow:</strong>{" "}
          {money(data?.financial_data?.monthly_operational_cashflow)}
        </p>
        <h3>Risks</h3>
        {data.risks.filter((risk) => risk.risk_description).map((risk, index) => (
          <div key={index}>
            <p> {risk.risk_description}</p>
            <h4>Mitigation</h4>
            <ul>
              {risk.mitigation.map((mitigation, idx) => (
                <li key={idx}>{mitigation}</li>
              ))}
            </ul>
          </div>
        ))}
        <h3>Recommendation</h3>
        <p>{data.recommendation}</p>
      </div>
    );
  };
  export default AssetEfficiency;
  